import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/ziqonfox-logo-word.svg'; // Adjust the path as needed

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  return (
    <nav className="border-gray-800 bg-white px-4 py-2.5 light:bg-cyan-400 lg:px-6">
      <div className="mx-auto flex max-w-screen-xl flex-wrap  items-center justify-between md:px-6 2xl:max-w-screen-2xl">
        <Link to="/" className="flex items-center">
          <img src={Logo} className="h-10 sm:h-12" alt="Logo" />
        </Link>
        <div className="flex items-center lg:order-2">
          <Link to="/contact-us" className="hidden rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 md:flex">
            Contact Us
          </Link>
          <button onClick={toggleMobileMenu} type="button" className="ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:text-gray-500 dark:hover:bg-gray-4 00 dark:focus:ring-cyan-400 lg:hidden">
            <span className="sr-only">Open main menu</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div className={`${mobileMenuOpen ? 'block' : 'hidden'} w-full items-center justify-between lg:order-1 lg:flex lg:w-auto`} id="mobile-menu-2">
          <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
            <li>
              <Link to="/" className="block border-b border-gray-100 py-2 pl-3 pr-4 text-cyan-700 hover:bg-cyan-50 dark:border-gray-200 dark:text-cyan-800 dark:hover:bg-cyan-100 dark:hover:text-cyan lg:border-0 lg:p-0 bg-transparent hover:text-sky-600 lg:dark:hover:bg-transparent">Home</Link>
            </li>
            <li>
              <Link to="/services" className="block border-b border-gray-100 py-2 pl-3 pr-4 text-cyan-700 hover:bg-cyan-50 dark:border-gray-200 dark:text-cyan-800 dark:hover:bg-cyan-100 dark:hover:text-cyan lg:border-0 lg:p-0 bg-transparent hover:text-sky-600 lg:dark:hover:bg-transparent">Services</Link>
            </li>
            <li>
              <Link to="/about" className="block border-b border-gray-100 py-2 pl-3 pr-4 text-cyan-700 hover:bg-cyan-50 dark:border-gray-200 dark:text-cyan-800 dark:hover:bg-cyan-100 dark:hover:text-cyan lg:border-0 lg:p-0 bg-transparent hover:text-sky-600 lg:dark:hover:bg-transparent">About</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav >
  );
};

export default Header;
