import React from 'react';
import image from '../../assets/manifest.png'

const ServiceItem = ({ icon, title, description }) => (
  <div className="pt-8">
    <svg
      className="h-12 w-12 flex-shrink-0 bg-gray-100 p-2 rounded"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      dangerouslySetInnerHTML={{ __html: icon }}
    />
    <div className="mt-5">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-800">{title}</h3>
      <div className="mt-2 h-0.5 bg-gradient-to-r from-cyan-400 via-cyan-100 to-white">
        <div className="h-0.5 w-9 bg-cyan-600"></div>
      </div>
      <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{description}</p>
    </div>
  </div>
);

const ServicesShowcase = () => {
  const services = [
    {
      icon: '<path d="M12 18H12.01M11 6H13M9.2 21H14.8C15.9201 21 16.4802 21 16.908 20.782C17.2843 20.5903 17.5903 20.2843 17.782 19.908C18 19.4802 18 18.9201 18 17.8V6.2C18 5.0799 18 4.51984 17.782 4.09202C17.5903 3.71569 17.2843 3.40973 16.908 3.21799C16.4802 3 15.9201 3 14.8 3H9.2C8.0799 3 7.51984 3 7.09202 3.21799C6.71569 3.40973 6.40973 3.71569 6.21799 4.09202C6 4.51984 6 5.07989 6 6.2V17.8C6 18.9201 6 19.4802 6.21799 19.908C6.40973 20.2843 6.71569 20.5903 7.09202 20.782C7.51984 21 8.07989 21 9.2 21Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>',
      title: "Mobile App Development",
      description: "Bring your vision to life with our top-tier native Android and iOS app development services, delivering excellence every step of the way."
    },
    {
      icon: '<path d="M5 2.25201C4.2255 2.4206 3.64118 2.70197 3.17157 3.17157C2 4.34315 2 6.22876 2 10V11C2 13.8284 2 15.2426 2.87868 16.1213C3.75736 17 5.17157 17 8 17H16C18.8284 17 20.2426 17 21.1213 16.1213C22 15.2426 22 13.8284 22 11V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C9.65081 2 9.31779 2 9 2.00093" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>< path d="M16 22H8" stroke="#1C274C" stroke- width="1.5" stroke - linecap="round" /><path d="M12 17L12 22" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M22 13H16M2 13H12" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>',
      title: "Web Development",
      description: "Overcome business challenges and excel in the digital world with our tailored web development solutions."
    },
    {
      icon: '<path d="M5 2.25201C4.2255 2.4206 3.64118 2.70197 3.17157 3.17157C2 4.34315 2 6.22876 2 10V11C2 13.8284 2 15.2426 2.87868 16.1213C3.75736 17 5.17157 17 8 17H16C18.8284 17 20.2426 17 21.1213 16.1213C22 15.2426 22 13.8284 22 11V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C9.65081 2 9.31779 2 9 2.00093" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>< path d="M16 22H8" stroke="#1C274C" stroke- width="1.5" stroke - linecap="round" /><path d="M12 17L12 22" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M22 13H16M2 13H12" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>',
      title: "UI/UX Design",
      description: "Boost user engagement and craft unforgettable experiences with our innovative design solutions."
    },
    {
      icon: '<path d="M14 21.7101C13.3663 21.8987 12.695 22 12 22C8.13401 22 5 18.866 5 15V11.9375C5 9.76288 6.76288 8 8.9375 8H15.0625C17.2371 8 19 9.76288 19 11.9375V15C19 16.9073 18.2372 18.6364 17 19.899" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M8.25 7.5C8.25 7.08579 7.91421 6.75 7.5 6.75C7.08579 6.75 6.75 7.08579 6.75 7.5H8.25ZM7.63452 4.58281C7.40411 4.92702 7.49636 5.39285 7.84058 5.62326C8.18479 5.85367 8.65062 5.76141 8.88103 5.41719L7.63452 4.58281ZM17.25 8.5V7.5H15.75V8.5H17.25ZM6.75 7.5V8.5H8.25V7.5H6.75ZM17.25 7.5C17.25 4.60051 14.8995 2.25 12 2.25V3.75C14.0711 3.75 15.75 5.42893 15.75 7.5H17.25ZM12 2.25C10.179 2.25 8.57506 3.17771 7.63452 4.58281L8.88103 5.41719C9.55501 4.41032 10.7005 3.75 12 3.75V2.25Z" fill="#1C274C" /><path d="M19 14H22" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M5 14H2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M14.5 3.5L17 2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M9.5 3.5L7 2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M20.5 20.0002L18.5 19.2002" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M20.5 7.9998L18.5 8.7998" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M3.5 20.0002L5.5 19.2002" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M3.5 7.9998L5.5 8.7998" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M12 21.5V15" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>',
      title: "Manual Testing",
      description: "Our manual QA engineers assess applications through the eyes of end users, offering actionable insights to enhance user experience well before your product goes live."
    },
    {
      icon: '<path d="M14 21.7101C13.3663 21.8987 12.695 22 12 22C8.13401 22 5 18.866 5 15V11.9375C5 9.76288 6.76288 8 8.9375 8H15.0625C17.2371 8 19 9.76288 19 11.9375V15C19 16.9073 18.2372 18.6364 17 19.899" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M8.25 7.5C8.25 7.08579 7.91421 6.75 7.5 6.75C7.08579 6.75 6.75 7.08579 6.75 7.5H8.25ZM7.63452 4.58281C7.40411 4.92702 7.49636 5.39285 7.84058 5.62326C8.18479 5.85367 8.65062 5.76141 8.88103 5.41719L7.63452 4.58281ZM17.25 8.5V7.5H15.75V8.5H17.25ZM6.75 7.5V8.5H8.25V7.5H6.75ZM17.25 7.5C17.25 4.60051 14.8995 2.25 12 2.25V3.75C14.0711 3.75 15.75 5.42893 15.75 7.5H17.25ZM12 2.25C10.179 2.25 8.57506 3.17771 7.63452 4.58281L8.88103 5.41719C9.55501 4.41032 10.7005 3.75 12 3.75V2.25Z" fill="#1C274C" /><path d="M19 14H22" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M5 14H2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M14.5 3.5L17 2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M9.5 3.5L7 2" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M20.5 20.0002L18.5 19.2002" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M20.5 7.9998L18.5 8.7998" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M3.5 20.0002L5.5 19.2002" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M3.5 7.9998L5.5 8.7998" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/><path d="M12 21.5V15" stroke="#1C274C" stroke-width="1" stroke-linecap="round"/>',
      title: "Automation Testing",
      description: "Elevate your digital products with our all-encompassing testing services, guaranteeing the highest quality and performance."
    },
    {
      icon: '<path d="M12 21V11M12 11L9 14M12 11L15 14M7 16.8184C4.69636 16.2074 3 14.1246 3 11.6493C3 9.20008 4.8 6.9375 7.5 6.5C8.34694 4.48637 10.3514 3 12.6893 3C15.684 3 18.1317 5.32251 18.3 8.25C19.8893 8.94488 21 10.6503 21 12.4969C21 14.8148 19.25 16.7236 17 16.9725" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>',
      title: "Cloud & DevOps",
      description: "Stay ahead of the competition with our agile DevOps solutions, expertly crafted to streamline your development processes, enhance efficiency, and adapt quickly to changing demands. Our tailored approach ensures your business remains agile, innovative, and poised for success."
    },
    {
      icon: '<path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"></path>',
      title: "Digital Marketting",
      description: "An all-inclusive suite of digital marketing services designed to fit your business, to cover every aspect of your marketing strategy."
    },
    {
      icon: '<path d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM6 9V21M6 9C6 12.5 8.5 18 14.5 18" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>',
      title: "CI/CD Pipelines",
      description: "Implement automated testing infrastructure and integrate it into your CI/ CD pipeline to enhance the reliability and confidence of your production releases"
    },
  ];

  return (
    <>
      <section className="bg-white light:bg-gray-900" id="services">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6 2xl:max-w-screen-2xl">
          <div className="mx-auto mb-8 max-w-screen-lg text-center lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-gray-900">
              <a href="#services">Services we Offer</a>
            </h2>
            <p className="mt-3 text-gray-800 dark:text-gray-400 md:text-lg">
              We deliver top-tier software solutions with clean, maintainable code, agile methodologies, and a focus on user-centric experiences, ensuring quality, innovation, and efficiency in every project we undertake.
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 md:gap-8 md:space-y-0 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
            {services.map((service, index) => (
              <ServiceItem key={index} {...service} />
            ))}
          </div>
        </div>
      </section>
      <section>
        <div>
          <div className="mt-5 mb-5 text-center">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-gray-900">
              <a href="#services">Design Process</a>
            </h2>
            <p className="mt-3 text-gray-800 dark:text-gray-400 md:text-lg">
              Our design process is user-centered, involving research, prototyping, and testing. We prioritize usability and functionality, ensuring each iteration improves the overall user experience and meets both client and user needs.
            </p>
            <br></br>
            <div className="flex justify-center items-center">
              <img
                className=" bg-gray-50 object-cover w-[600px]"
                src={image}
                alt="Team working together"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesShowcase;