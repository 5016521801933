import React from 'react';

const FeatureCard = ({ icon, title, description }) => (
  <div>
    {icon}
    <h3 className="mb-2 text-xl font-bold dark:text-black">{title}</h3>
    <p className="mb-4 text-justify text-base text-gray-500 dark:text-gray-400">{description}</p>
  </div>
);

const ITOutsourcingSection = () => {
  const features = [
    {
      icon: (
        <svg class="mx-auto mb-4 h-12 w-12 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14" />
        </svg>

      ),
      title: "Best Engineering Practices",
      description: "Outsourcing to ZIqonfox means entrusting your software development to a team that combines expert engineering practices with a commitment to quality and transparency. We manage all or part of your project with precision and reliability, ensuring exceptional results through a seamless and proven development process."
    },
    {
      icon: (
        <svg className="mx-auto mb-4 h-12 w-12 text-sky-600 dark:text-sky-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 8v8m0-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V9a3 3 0 0 0-3-3h-3m1.5-2-2 2 2 2" />
        </svg>

      ),
      title: "Agile Teams, Expertly Managed",
      description: "Our remote agile team engagement model has been meticulously developed and refined over time, ensuring a seamless and efficient collaboration. With Ziqonfox, you don’t just gain access to highly vetted engineers, but also benefit from a proven, transparent process backed by a strong track record of successful project deliveries. This approach guarantees reliability, adaptability, and excellence at every step of your software development journey."
    },
    {
      icon: (
        <svg className="mx-auto mb-4 h-12 w-12 text-sky-600 dark:text-sky-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5" />

        </svg>
      ),
      title: "Unleash Unlimited Growth",
      description: "Our flexible global delivery center transforms engineering bottlenecks into growth drivers. Whether you need to scale your team rapidly or adapt to changing demands, Ziqonfox provides a seamless solution. With our expertise, you can overcome development challenges and accelerate your growth. From enhancing team capabilities to driving project success, we're just a call away, ready to support your expansion and help you achieve your goals with efficiency and innovation."
    }
  ];

  return (
    <section className="bg-white dark:bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 text-center sm:py-16 lg:px-6 2xl:max-w-screen-2xl">
        <h2 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 dark:text-black sm:text-4xl">
          Your Trusted Full-Stack IT Partner
        </h2>
        <p className="text-gray-500 dark:text-gray-400 sm:text-xl">
          Our culture aligns seamlessly with yours, embodying agility in thought, approach, and execution.
        </p>
        <div className="mt-8 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0 lg:mt-12 lg:grid-cols-3">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ITOutsourcingSection;