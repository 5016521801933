import React from 'react';
import Hero from '../../components/Hero';
import FoundersCTA from '../../components/FoundersCTA';
import TestimonialsSection from '../../components/TestimonialSection';
import ServicesSection from '../../components/Services';
import ITOutsourcingSection from '../../components/ITOutsourcing';
import IndustriesServedSection from '../../components/Industries';
import EngagementModelsSection from '../../components/Engagement';
// import WorkShowcase from '../../components/WorkShowCase';
// import ConsistencyStats from '../../components/ConsistencyStats';
// import ClientShowcase from '../../components/ClientShowCase';
// import BlogShowcase from '../../components/BlogShowCase';
// import CultureFeature from '../../components/CultureFeature';
import TechShowcase from '../../components/TechShowCase';

function Home() {
  return (
    <div className="Home">
      <Hero />
      <ServicesSection />
      <ITOutsourcingSection />
      <IndustriesServedSection />
      <EngagementModelsSection />
      {/* <WorkShowcase /> */}
      {/* <ConsistencyStats /> */}
      {/* <ClientShowcase /> */}
      {/* <BlogShowcase /> */}
      {/* <TestimonialsSection /> */}
      {/* <CultureFeature /> */}
      <TechShowcase />
      <FoundersCTA />
    </div>
  );
}

export default Home;