import React from 'react';
import FoundersCTA from '../../components/FoundersCTA';
import ServicesShowcase from '../../components/ServiceShowcase';

function Services() {
    return (
        <div className="Services">
            <ServicesShowcase />
            <FoundersCTA />
        </div>
    );
}

export default Services;